import { Box, Grid } from "@mui/material";
import React from "react";
import { QRCode } from "react-qrcode-logo";
import tritophiaLogo from "./Assets/Images/tritophia-logo.png";
import tritophiaBackground from "./Assets/Images/tritophia-background.png";
import tritophiaPurple from "./Assets/Images/tritophia-purple.png";
import "./App.css";

const App = () => {
  const items = [
    {
      path: "https://preview.tritophia.com/preview?integrationId=2&languageId=1&productId=1",
      image:
        "https://res.cloudinary.com/dp058wqz8/image/upload/v1728593376/x2av4e4puxl3a9ixfitz.glb",
    },
    {
      path: "https://preview.tritophia.com/preview?integrationId=2&languageId=1&productId=2",
      image:
        "https://res.cloudinary.com/dp058wqz8/image/upload/v1728593424/gz5xzyfg7rl1hcwzlibv.glb",
    },
    {
      path: "https://preview.tritophia.com/preview?integrationId=2&languageId=1&productId=3",
      image:
        "https://res.cloudinary.com/dp058wqz8/image/upload/v1728593452/ef9cs2dpn5yb91o1cqk4.glb",
    },
    {
      path: "https://preview.tritophia.com/preview?integrationId=2&languageId=1&productId=4",
      image:
        "https://res.cloudinary.com/dp058wqz8/image/upload/v1728593482/zvdq2qimitux2esgq9xc.glb",
    },
    {
      path: "https://preview.tritophia.com/preview?integrationId=2&languageId=1&productId=5",
      image:
        "https://res.cloudinary.com/dp058wqz8/image/upload/v1728593506/oobhhdgyltyyigmfz8u4.glb",
    },
    {
      path: "https://preview.tritophia.com/preview?integrationId=2&languageId=1&productId=6",
      image:
        "https://res.cloudinary.com/dp058wqz8/image/upload/v1728593535/pvwohj9npfvoq2xwpafq.glb",
    },
    {
      path: "https://preview.tritophia.com/preview?integrationId=2&languageId=1&productId=7",
      image:
        "https://res.cloudinary.com/dp058wqz8/image/upload/v1728593559/dfkisb0vv1rsfa6ksmw3.glb",
    },
  ];

  return (
    <Box>
      <Grid container className="navbar">
        <Grid item xs={0} lg={2}></Grid>
        <Grid item xs={12} lg={8} sx={{ paddingTop: "10px" }}>
          <Box sx={{ mt: "10px", ml: "-20px" }}>
            <img src={tritophiaPurple} height={90} alt="logo" />
          </Box>
        </Grid>
        <Grid item xs={0} lg={2}></Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{ backgroundImage: `url(${tritophiaBackground})` }}
      >
        <Grid item xs={1} lg={2} sx={{ p: 0 }}></Grid>
        <Grid item xs={10} lg={8}>
          {items?.map((item, index) => (
            <Grid container className="itemBox" key={index}>
              <Grid
                item
                xs={0}
                lg={6}
                sx={{
                  textAlign: "center",
                  display: { xs: "none", md: "block" },
                }}
              >
                <QRCode
                  size={356}
                  quietZone={10}
                  eyeColor="#993BE1"
                  eyeRadius={7}
                  qrStyle="dots"
                  logoImage={tritophiaLogo}
                  logoHeight={70}
                  logoWidth={65}
                  logoPaddingStyle="square"
                  value={item.path}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                sx={{ textAlign: "center", height: 376 }}
              >
                <model-viewer
                  alt="chair"
                  src={item.image}
                  ar
                  camera-controls
                  disable-tap
                  touch-action="none"
                  camera-orbit={item.cameraOrbit}
                ></model-viewer>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={1} lg={2}></Grid>
      </Grid>
    </Box>
  );
};

export default App;
